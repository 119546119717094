import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CRUDService } from '../_services/crud.service';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  FormControl
} from '@angular/forms';
import { LayoutUtilsService } from '../../core/_base/crud';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'kt-add-labs',
  templateUrl: './add-labs.component.html',
  styleUrls: ['./add-labs.component.scss']
})
export class AddLabsComponent implements OnInit {
  @Input() isFocus: boolean = true;
  @Input() query;
  @Input() fetchAPI;
  @Input() creteOrderFlag: boolean = false;
  @Output() notifyParent = new EventEmitter<any>();
  filteredOptions: Observable<string[]>;
  public formData: any[];
  public formGroup: FormGroup;
  public title: string = 'Add Lab';
  public loading: boolean;
  public nonAddedLabs;
  public selectedLabs = [];
  public labValidation: boolean = false;

  myControl = new FormControl();
  public labsForm = new FormGroup({
    labs: new FormArray([])
  });

  @ViewChildren('focusInput') rows: QueryList<any>;

  constructor(
    public activeModal: NgbActiveModal,
    private _crudService: CRUDService,
    private layoutUtilsService: LayoutUtilsService,
    private _formBuilder: FormBuilder,
  ) { }


  onDomChange($event: Event): void {
    this.rows.last.nativeElement.focus();
  }

  ngOnInit() {

    this.addLabItem();
    this.loading = true;
    // console.log(
    //   'AddLabsComponent -> ngOnInit -> this.query',
    //   this.query
    // );

    this._crudService
      .getData(this.fetchAPI || 'labs', this.query)
      .then((cerRes) => {

        this.loading = false;
        this.nonAddedLabs = cerRes.data;

        const bundleArray = cerRes.bundles

        this.nonAddedLabs.push(...bundleArray)

      })
      .catch((err) => {
        // TODO: handle ERROR
        this.layoutUtilsService.showActionNotification(
          'Failed to fetch labs, kindly refresh'
        );
        this.loading = false;
        console.error(err);
      });
  }


  private _filter(value: any): string[] {
    console.log('AddLabsComponent -> value', value);
    console.log(
      'AddLabsComponent -> this.selectedLabs',
      this.selectedLabs
    );

    if (typeof value !== 'string') {
      this.labValidation = true
      return;
    }

    const filterValue = value.toLowerCase();

    if (typeof value !== 'object') {
      this.labValidation = true
    }


    this.labValidation = false
    return this.nonAddedLabs.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(opt: any): string {
    // console.log('AddLabsComponent -> displayFn -> opt', opt);
    return opt && opt.name ? opt.name : '';
  }

  /*############### Add Dynamic Elements ###############*/
  get getFormArray() {
    return this.labsForm.get('labs') as FormArray;
  }


  addLabItem() {

    let newFormControl = new FormControl(null, Validators.required);
    this.getFormArray.push(
      new FormGroup({
        lab: newFormControl
      })
    );

    this.filteredOptions = newFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value)),
      map((name) =>
        name ? this._filter(name) : this.nonAddedLabs.slice()
      )
    );

  }

  formChanged() {
    console.log(this.labsForm.value);
  }

  deleteLast() {
    this.getFormArray.removeAt(this.getFormArray.length - 1);
  }

  // Submit Form
  onSubmit() {
    console.log(
      'AddLabsComponent -> onSubmit -> this.labsForm.value.labs',
      this.labsForm.value.labs
    );

    if (this.creteOrderFlag) {
      const labs = this.labsForm.value.labs.map(obj => obj.lab);
      this.notifyParent.emit(labs);
    } else {
      const labIDs = this.labsForm.value.labs
        .filter((x) => x.lab)
        .map((x) => ({ lab: x.lab.id }));
      this.notifyParent.emit(labIDs);
    }
    this.activeModal.close('success');
  }
}
