import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, retry } from "rxjs/operators";
import {of } from "rxjs";
import { CheckoutService } from "../../services/checkout.service";
import * as fromCheckoutActions from '../actions/checkout.actions';

@Injectable()
export class CheckoutEffects {

    constructor(private actions$: Actions, private checkoutService: CheckoutService) {
        
    }
    book$ = createEffect(
        () => this.actions$.pipe(
          ofType(fromCheckoutActions.booking.type),
          concatMap(
           ( payload: any )=> {
              return this.checkoutService.book(payload.payload)
              .pipe(
              map(
                (response: any) => 
                (fromCheckoutActions.bookingSuccess({ payload: response }))),
                catchError((error) => of(fromCheckoutActions.bookingFailure(error)))
              )
            }
          )
        )
    );

    bookById$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromCheckoutActions.getBookingById.type),
        concatMap(
         ( payload: any )=> {
            return this.checkoutService.getVisitById(payload.payload)
            .pipe(
            map(
              (response: any) => 
              (fromCheckoutActions.getBookingByIdSuccess({ payload: response }))),
              catchError((error) => of(fromCheckoutActions.getBookingByIdFailure(error)))
            )
          }
        )
      )
    );

    cancelBook$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromCheckoutActions.cancelBooking.type),
        concatMap(
         ( payload: any )=> {
            return this.checkoutService.cancelBook(payload.payload)
            .pipe(
            map(
              (response: any) => 
              (fromCheckoutActions.cancelBookingSuccess({ payload: response }))),
              catchError((error) => of(fromCheckoutActions.cancelBookingFailure(error)))
            )
          }
        )
      )
  );

}