import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  LayoutUtilsService,
  MessageType
} from '../../core/_base/crud/utils/layout-utils.service';
import { CRUDService } from '../../shared/_services/crud.service';

@Component({
  selector: 'kt-add-chemist-to-lab',
  templateUrl: './add-chemist-to-lab.component.html',
  styleUrls: ['./add-chemist-to-lab.component.scss']
})
export class AddChemistToLabComponent implements OnInit {
  public formData = {};
  private chemists = [];
  @Input() lab;

  public userProfileModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          inputs: [
            {
              name: 'Chemist(s)',
              type: 'select',
              gate: 'chemists',
              apiend: 'chemists',
              multiple: true,
              options: [],
              required: true
            }
          ]
        }
      ]
    }
  };

  @Output()
  notifyParent: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    public crudService: CRUDService,
    private layoutUtilService: LayoutUtilsService
  ) {}

  async ngOnInit() {
    const chemistsResponse = await this.crudService.api(
      'api/chemists',
      { limit: 9999 }
    );
    this.chemists = chemistsResponse.data.map((c) => {
      return {
        id: c.id,
        label: [c.userprofile.firstName, c.userprofile.lastName]
          .filter(Boolean)
          .join(' ')
      };
    });
    this.userProfileModel.form.groups[0].inputs[0].options =
      this.chemists;
  }

  submitForm(profileData) {
    this.formData = {
      chemists: profileData.chemists,
      lab: this.lab.id
    };
    this.crudService
      .api('api/labchemists', this.formData, 'POST')
      .then((response) => {
        this.activeModal.close('success');
        this.notifyParent.emit(response);
        this.layoutUtilService.showActionNotification(
          'Chemist(s) added',
          MessageType.Read
        );
      })
      .catch((err) => {
        this.layoutUtilService.showActionNotification(
          err.error.message || 'Failed to add chemist(s)',
          MessageType.Read
        );
      });
  }

  closeModal(event) {
    this.activeModal.close();
  }
}
