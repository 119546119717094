import { Component, OnDestroy, OnInit } from '@angular/core';
import {  Subscription } from 'rxjs';
import { ClinicViewModel } from '../../models/clinicViewModel.interface';
import { ClinicService } from '../../services/clinics.service';

@Component({
  selector: 'clinic-header',
  templateUrl: './clinic-header.component.html',
  styleUrls: ['./clinic-header.component.scss']
})
export class ClinicHeaderComponent implements OnInit, OnDestroy {
 
  private subscription!: Subscription;
  clinic!: ClinicViewModel;
  showEdit!: boolean;
  showBar!: boolean;
  constructor(private clinicService: ClinicService) { }
 
  ngOnInit(): void {
    this.subscription = this.clinicService.currentSelectedClinic$.subscribe(({clinic, mode}) => {
        if(clinic) {
          this.clinic = clinic;
        } 
        this.showEdit = mode === 'readonly';
        this.showBar = mode !== 'add';
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  edit() {
    this.clinicService.currentSelectedClinic$.next({clinic: this.clinic, mode: "edit"});
  }
}
