import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BookingRequest } from "../../models/booking.interface";
import * as fromReducer from '../reducers/checkout.reducer';
import * as fromActions from '../actions/checkout.actions';
import { Observable } from "rxjs";
@Injectable({
    providedIn: 'root'
  })
  export class CheckoutProvider {
  
    constructor(private store: Store<fromReducer.ModuleState>) {
    }

    public dispatchBooking(booking: BookingRequest) {
      this.store.dispatch(fromActions.booking({payload: booking}));
    }
    public dispatchgetBookingById(visitId: string) {
      this.store.dispatch(fromActions.getBookingById({payload: visitId}));
    }
    public dispatchCancelBooking(visitId: number) {
      this.store.dispatch(fromActions.cancelBooking({payload: visitId}));
    }
    public selectGetBookingById$() {
      return this.store.pipe(select(fromReducer.selectBookingById));
    }

    public selectBooking$(): Observable<any> {
      return this.store.pipe(select(fromReducer.selectBooking));
    }
}