import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'daysTranslator'
})
export class DaysTranslatorPipe implements PipeTransform {
  transform(value: string): string {
    if (!value.length) return '';
    const daysArray = value.split(',');
    moment.locale('en');
    return daysArray
      .map((day) => {
        const fullDay = `${day}day`;
        return moment(fullDay, 'dddd').locale('us').format('dddd');
      })
      .join(', ');
  }
}
