import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { MainPage } from "../../../main.page";

import { CheckoutProvider } from "../../state/providers/checkout.provider";
import { Router } from "@angular/router";
import { ConfrimModalComponent, DialogData } from "../../confrim-modal/confrim-modal.component";

@Component({
  selector: "checkout-success",
  templateUrl: "./checkout-success.component.html",
  styleUrls: ["./checkout-success.component.scss"],
})
export class CheckoutSuccessPage extends MainPage implements OnInit {
  bookingData: any = {};
  constructor(
    private checkoutProvider: CheckoutProvider,
    private dialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkoutProvider
      .selectBooking$()
      .pipe(take(2))
      .subscribe((data) => {
        if (data) this.bookingData = data;
      });
  }

  cancelVisit(visitId: number) {
    let dialogData: DialogData;
    dialogData = {
      // title: $localize`Logout`,
      // message: $localize`Are you sure want to cancel your reservation?`,
      // buttonText: $localize`Confirm`,
      title: `Logout`,
      message: `Are you sure want to cancel your reservation?`,
      buttonText: `Confirm`,
      action: () => this.onConfirmCancelVisit(visitId),
      showCancel: true,
      icon: "assets/images/warning-icon.svg",
    };

    this.dialog.open(ConfrimModalComponent, {
      data: dialogData,
    });
  }

  onConfirmCancelVisit(visitId: number) {
    this.checkoutProvider.dispatchCancelBooking(visitId);
    this.router.navigate([`visits/details/${visitId}`]);
  }
}
