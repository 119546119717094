import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { LayoutUtilsService } from '../../core/_base/crud';
import { CRUDService } from '../_services/crud.service';

// Generic component to "select" bundles and notify its parent
@Component({
  selector: 'kt-add-bundles',
  templateUrl: './add-bundles.component.html',
  styleUrls: ['./add-bundles.component.scss']
})
export class AddBundlesComponent implements OnInit {
  @Input() isFocus: boolean = true;
  @Input() query;
  @Input() fetchAPI;
  @Input() fetchAPIHeaders;
  @Input() creteOrderFlag: boolean = false;
  @Output() notifyParent = new EventEmitter<any>();
  filteredOptions: Observable<string[]>;
  public formData: any[];
  public formGroup: FormGroup;
  public title: string = 'Add Bundle';
  public loading: boolean;
  public nonAddedBundles;
  public selectedBundles = [];
  public bundleValidation: boolean = false;

  myControl = new FormControl();
  public bundlesForm = new FormGroup({
    bundles: new FormArray([])
  });

  @ViewChildren('focusInput') rows: QueryList<any>;

  constructor(
    public activeModal: NgbActiveModal,
    private _crudService: CRUDService,
    private layoutUtilsService: LayoutUtilsService,
    private _formBuilder: FormBuilder
  ) {}

  onDomChange($event: Event): void {
    this.rows.last.nativeElement.focus();
  }

  ngOnInit() {
    this.addBundleItem();
    this.loading = true;

    this._crudService
      .getData(
        this.fetchAPI || 'bundles',
        this.query,
        this.fetchAPIHeaders
      )
      .then((cerRes) => {
        this.loading = false;
        this.nonAddedBundles = cerRes.map((b) => {
          return {
            ...b,
            id: b.bundle.id,
            name: b.bundle.name
          };
        });
      })
      .catch((err) => {
        this.layoutUtilsService.showActionNotification(
          'Failed to fetch bundles, kindly refresh'
        );
        this.loading = false;
        console.error(err);
      });
  }

  private _filter(value: any): string[] {
    if (typeof value !== 'string') {
      this.bundleValidation = true;
      return;
    }

    const filterValue = value.toLowerCase();

    if (typeof value !== 'object') {
      this.bundleValidation = true;
    }

    this.bundleValidation = false;
    return this.nonAddedBundles.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        (option.name_ar &&
          option.name_ar.toLowerCase().includes(filterValue))
    );
  }

  displayFn(opt: any): string {
    return opt && opt.name ? opt.name : '';
  }

  /*############### Add Dynamic Elements ###############*/
  get getFormArray() {
    return this.bundlesForm.get('bundles') as FormArray;
  }

  addBundleItem() {
    let newFormControl = new FormControl(null, Validators.required);
    this.getFormArray.push(
      new FormGroup({
        bundle: newFormControl
      })
    );

    this.filteredOptions = newFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value)),
      map((name) =>
        name ? this._filter(name) : this.nonAddedBundles.slice()
      )
    );
  }

  formChanged() {
    console.log(this.bundlesForm.value);
  }

  deleteLast() {
    this.getFormArray.removeAt(this.getFormArray.length - 1);
  }

  // Submit Form
  onSubmit() {
    if (this.creteOrderFlag) {
      const bundles = this.bundlesForm.value.bundles.map(
        (obj) => obj.bundle
      );
      this.notifyParent.emit(bundles);
    } else {
      const bundleIDs = this.bundlesForm.value.bundles
        .filter((x) => x.bundle)
        .map((x) => ({ bundle: x.bundle.id }));
      this.notifyParent.emit(bundleIDs);
    }
    this.activeModal.close('success');
  }
}
