import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutSuccessPage } from './pages/checkout-success/checkout-success.component';
import { BookingConfirmationModal } from './components/booking-confirmation-modal/booking-confirmation-modal.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import * as fromFeatureState from "./state/reducers/checkout.reducer";
import { EffectsModule } from '@ngrx/effects';
import { CheckoutEffects } from './state/effects/checkout.effects';
import { SharedModule } from '../shared/shared.module';
import { CalendarComponent } from './components/calendar/calendar.component';
import { VisitsModule } from '../visits/visits.module';
import { VisitsComponent } from '../visits/visits/visits.component';
import { ConfrimModalComponent } from './confrim-modal/confrim-modal.component';
@NgModule({
  declarations: [
    CheckoutSuccessPage,
    BookingConfirmationModal,
    CalendarComponent,
    ConfrimModalComponent
  ],
  imports: [
    VisitsModule,
    CommonModule,
    CheckoutRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    // MatSelectCountryModule,
    MatDialogModule,
    StoreModule.forFeature(fromFeatureState.checkoutFeatureKey, fromFeatureState.checkOutReducer),
    EffectsModule.forFeature([CheckoutEffects]),
  ],
  entryComponents: [
    BookingConfirmationModal,VisitsComponent,ConfrimModalComponent
  ],
  exports:[]
})
export class CheckoutModule {}
