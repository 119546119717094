import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'doctor-dashboard-button',
  templateUrl: './doctor-dashboard-button.component.html',
  styleUrls: ['./doctor-dashboard-button.component.scss']
})
export class DoctorDashboardButtonComponent {
  @Input() btnText!: string;
  @Input() disabled!: boolean;
  @Input() showIcon: boolean = true;
  @Output() buttonClickedEvent: EventEmitter<void>;
  constructor() {
    this.buttonClickedEvent = new EventEmitter();
  }
  buttonClicked() {
    this.buttonClickedEvent.emit();
  }
}
