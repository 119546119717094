// import { Country } from '@angular-material-extensions/select-country';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { skip, take } from 'rxjs/operators';
import { BookingRequest } from '../../models/booking.interface';
import { CheckoutProvider } from '../../state/providers/checkout.provider';
import { PeriodRange } from '../../../shared/models/schedule.interface';
@Component({
  selector: 'booking-confirmation-modal',
  templateUrl: './booking-confirmation-modal.component.html',
  styleUrls: ['./booking-confirmation-modal.component.scss']
})
export class BookingConfirmationModal implements OnInit {
  bookingForm!: FormGroup;
  time: moment.Moment;
  otpValue: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {period: PeriodRange, 
    day: string, clinicId: number, name: string, phone: string},
   private formBuilder: FormBuilder, 
   private router: Router, 
   private checkoutProvider: CheckoutProvider,
   public dialogRef: MatDialogRef<BookingConfirmationModal>) {
      this.time = moment(data.day, "YYYY-MM-DD");
   }

  ngOnInit(): void {
    this.bookingForm = this.formBuilder.group({
      // countryCode: new FormControl({alpha2Code: "EG"} as Country, Validators.required),
      countryCode: '+20',
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^1[0-2,5]{1}[0-9]{8}$/)]),
      userName: new FormControl('', [Validators.required])
    });
  }
  get countryCode() {
    return this.bookingForm.get('countryCode');
  }
  get phoneNumber() {
    return this.bookingForm.get('phoneNumber');
  }
  get userName() {
    return this.bookingForm.get('userName');
  }
  onBookingClicked() {
    const booking: BookingRequest = {
      patientName: this.userName.value,
      clinicId: this.data.clinicId,
      dayDate: this.time.format('YYYY-MM-DD'),
      startsAt: this.data.period.startsAt,
      patientPhone: this.phoneNumber.value,
    }
    this.checkoutProvider.dispatchBooking(booking);
    // localStorage.removeItem('lastBook');
    this.checkoutProvider.selectBooking$().pipe(take(2)).subscribe((bookingData)=> {
      if(!bookingData) return;
      this.dialogRef.close();
      this.router.navigateByUrl(`checkout/${bookingData.id}`);
    });
  }

}

