import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stepper-counter',
  templateUrl: './stepper-counter.component.html',
  styleUrls: ['./stepper-counter.component.scss']
})
export class StepperCounterComponent implements OnInit, OnDestroy {
  private initialValueSubject = new BehaviorSubject<number>(0);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input()
  set initialValue(value: number) {
    this.initialValueSubject.next(value);
  }
  get initialValue() {
    return this.renderedValue;
  }
  @Input() step!: number;
  @Input() unit!: string;
  @Input() readonly!: boolean;
  @Output() onChange = new EventEmitter<number>();
  value: number = 0;
  renderedValue!: number;
  constructor() {
    this.initialValueSubject
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.value = value;
        this.renderedValue = value;
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
  toggleMore() {
    this.value = this.value + this.step;
    this.renderedValue = this.value;
    this.onChange.emit(this.renderedValue);
  }

  toggleLess() {
    this.value = this.value - this.step;
    this.renderedValue = this.value;
    this.onChange.emit(this.renderedValue);
  }
  ngOnInit(): void {
    this.renderedValue = this.initialValue;
    this.value = this.initialValue;
  }
}
