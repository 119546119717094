import {
  Component,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import {
  LayoutUtilsService,
  MessageType
} from '../../core/_base/crud';
import { CRUDService } from '../../shared/_services/crud.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { clean } from '../../../utils/helpers';

@Component({
  selector: 'kt-create-medical-profile',
  templateUrl: './create-medical-profile.component.html',
  styleUrls: ['./create-medical-profile.component.scss']
})
export class CreateMedicalProfileComponent {
  @Input() auth: string;

  public formData = {};

  public activeStep = 1;
  public totalSteps = 2;

  public profileModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          inputs: [
            {
              name: 'Name',
              gate: 'name',
              type: 'string',
              required: true
            },
            {
              name: 'Gender',
              gate: 'gender',
              type: 'select',
              required: true,
              simpleOpts: true,
              options: ['male', 'female']
            },
            {
              name: 'Phone',
              gate: 'phone',
              type: 'number',
              required: true
            },
            {
              name: 'Date of Birth',
              gate: 'birthDate',
              type: 'date',
              required: true
            },
            {
              name: 'Weight',
              gate: 'weight',
              type: 'number'
            },
            {
              name: 'Height',
              gate: 'height',
              type: 'number'
            }
          ]
        }
      ]
    }
  };

  public medicalDataModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          inputs: [
            {
              name: 'Medications',
              type: 'select',
              gate: 'medications',
              apiend: 'medications',
              multiple: true,
              query: { limit: 9999 }
            },
            {
              name: 'Other medications',
              gate: 'meds',
              type: 'string'
            },
            {
              name: 'Latest period',
              gate: 'latest_period',
              type: 'date'
            },
            {
              name: 'Complaint',
              gate: 'complaint',
              type: 'textarea'
            },
            {
              name: 'Antibiotics',
              type: 'boolean',
              gate: 'antibiotics'
            },

            {
              name: 'Diabetes',
              type: 'boolean',
              gate: 'diabetes'
            },
            {
              name: 'Cancer',
              type: 'boolean',
              gate: 'cancer'
            },
            {
              name: 'Hypertension',
              type: 'boolean',
              gate: 'hypertension'
            },
            {
              name: 'Cardiac',
              type: 'boolean',
              gate: 'cardiac'
            },
            {
              name: 'Thyroid',
              type: 'boolean',
              gate: 'thyroid'
            },
            {
              name: 'Epilepsy',
              type: 'boolean',
              gate: 'epilepsy'
            },
            {
              name: 'Chronic liver',
              type: 'boolean',
              gate: 'chronic_liver'
            },
            {
              name: 'Chronic kidney',
              type: 'boolean',
              gate: 'chronic_kidney'
            },
            {
              name: 'Allergy',
              type: 'boolean',
              gate: 'allergy'
            },
            {
              name: 'Pregnancy',
              type: 'boolean',
              gate: 'pregnancy'
            }
          ]
        }
      ]
    }
  };

  @Output()
  notifyParent: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    public crudService: CRUDService,
    private layoutUtilService: LayoutUtilsService
  ) {}

  moveToMedicalDataStep(data) {
    this.formData['medicalProfile'] = data;
    this.activeStep++;
  }

  submitForm(profileData) {
    this.formData['medicalProfile'] = {
      ...this.formData['medicalProfile'],
      ...profileData
    };

    this.formData['medicalProfile'] = clean(
      this.formData['medicalProfile']
    );
    this.formData['auth'] = this.auth;

    this.crudService
      .api('api/othersmedicalprofiles', { ...this.formData }, 'POST')
      .then((response) => {
        this.activeModal.close('success');
        this.notifyParent.emit(response);
        this.layoutUtilService.showActionNotification(
          'Created new medical profile successfully',
          MessageType.Read
        );
      })
      .catch((err) => {
        this.layoutUtilService.showActionNotification(
          'Failed to create medical profile',
          MessageType.Read
        );
      });
  }

  goBackStep(event) {
    this.activeStep--;
  }

  closeModal(event) {
    this.activeModal.close();
  }
}
