import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-add-tests',
  templateUrl: './test-and-bundle-conflict-warning.component.html',
  styleUrls: ['./test-and-bundle-conflict-warning.component.scss']
})
export class TestAndBundleConflictWarningComponent {
  @Input() cancelledTests: any;
  @Input() cancelledTestsIds: any;
  @Input() addedItems: any;

  @Output() notifyParent = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  onSubmit() {
    this.activeModal.close();
    this.notifyParent.emit('cancelledTestsIds');
  }

  onCancel() {
    this.activeModal.close();
    this.notifyParent.emit('addedItems');
  }
}
