import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  socket: any;
  readonly url: string = environment.url;

  constructor() {
    this.socket = io.connect(this.url, { transports: ['websocket'], query: { admin: true } });
  }

  listen(eventName: string) {
    return new Observable(subscriber => {
      this.socket.on(eventName, data => {
        subscriber.next(data);
      })
      return () => {
        this.socket.disconnect();
      }
    })
  }

  removeListener(eventName: string) {
    this.socket.off(eventName);
  }
}
