// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CRUDService } from '../../../../../app/shared/_services/crud.service';
@Component({
  selector: 'kt-delete-entity-dialog',
  templateUrl: './delete-entity-dialog.component.html'
})
export class DeleteEntityDialogComponent implements OnInit {
  // Public properties
  public viewLoading: boolean = false;
  public cancellationReason = null;
  public cancellationReasons = [];
  public otherCancellationReason = '';
  public displayOtherCancellationReason = false;

  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
   * @param data: any
   */
  constructor(
    private _crudService: CRUDService,
    public dialogRef: MatDialogRef<DeleteEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    if (this.data.cancellationReason) {
      this._crudService
        .api(`/api/cancellationreasons`)
        .then((res) => {
          this.cancellationReasons = res.data;
          this.cancellationReasons.push({
            id: 'other',
            name: 'Another Reason Not Mentioned'
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onChange($event) {
    if ($event.target.value === 'other') {
      this.displayOtherCancellationReason = true;
      this.cancellationReason = null;
    } else {
      this.displayOtherCancellationReason = false;
    }
  }

  /**
   * Close dialog with false result
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Close dialog with true result
   */
  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.componentInstance.cancellationReason =
        this.cancellationReason;
      this.dialogRef.componentInstance.otherCancellationReason =
        this.otherCancellationReason ||
        'Another Reason Not Mentioned';
      this.dialogRef.close(true); // Keep only this row
    }, 2500);
  }
}
