import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  private isPatientModuleRequest(req: HttpRequest<any>): boolean {
    return req.url.includes('/patients/visits');
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.isPatientModuleRequest(req)) {      
      return next.handle(req);
    }
    const token = localStorage.getItem('x-access-token');
    if (token) {
      const clonedReq = req.clone({
        headers: req.headers.set('x-access-token', token)
      });
      return next.handle(clonedReq);
    } else {
      return next.handle(req);
    }
  }
}
