import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  LayoutUtilsService,
  MessageType
} from '../../core/_base/crud';
import { CRUDService } from '../../shared/_services/crud.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {
  @Input() creteOrderFlag: boolean = false;

  public formData = {};

  public activeStep = 1;
  public totalSteps = 2;
  public authModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          title: 'Auth Account',
          inputs: [
            {
              name: 'Email',
              type: 'string',
              gate: 'email',
              email: true,
              required: true
            },
            {
              name: 'Password',
              type: 'password',
              gate: 'password',
              required: true,
              minLength: 8
            }
          ]
        }
      ]
    }
  };

  public userProfileModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          inputs: [
            {
              name: 'First Name',
              gate: 'firstName',
              type: 'string',
              required: true
            },
            {
              name: 'Last Name',
              gate: 'lastName',
              type: 'string'
            },

            {
              name: 'Phone',
              gate: 'phone',
              type: 'phone',
              required: true
            },

            {
              name: 'gender',
              type: 'select',
              required: true,
              simpleOpts: true,
              options: ['male', 'female']
            },

            {
              name: 'Date of Birth',
              gate: 'birthDate',
              type: 'date',
              required: true,
            },
            {
              name: 'Area',
              type: 'select',
              required: true,
              gate: 'area',
              apiend: 'areas',
              query: { limit: 9999 }
            }
          ]
        }
      ]
    }
  };

  @Output()
  notifyParent: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    public crudService: CRUDService,
    private layoutUtilService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.creteOrderFlag ? this.activeStep = 2 : this.activeStep = 1;
  }

  moveToChemistStep(data) {
    this.formData['auth'] = data;
    this.activeStep++;
  }

  submitForm(profileData) {
    this.formData['userProfile'] = profileData;
    this.crudService
      .api('api/auth/userprofiles/create', { ...this.formData, createOrderFlag: this.creteOrderFlag }, 'POST')
      .then((response) => {
        this.activeModal.close('success');
        this.notifyParent.emit(response);
        this.layoutUtilService.showActionNotification(
          'Created new account successfully',
          MessageType.Read
        );
        // created auth account
      })
      .catch((err) => {
        const errMessage =
          (err.error && err.error.message) ||
          'Failed to create account';
        this.layoutUtilService.showActionNotification(
          errMessage,
          MessageType.Read
        );
      });
  }

  goBackStep(event) {
    this.creteOrderFlag ? this.activeStep = 2 : this.activeStep--;
  }

  closeModal(event) {
    this.activeModal.close();
  }
}
