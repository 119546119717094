import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutSuccessPage } from './pages/checkout-success/checkout-success.component';
import { AuthGuard } from '../services/auth.guard';
import { VisitsComponent } from '../visits/visits/visits.component';

const routes: Routes = [
  // { path: '', component: DoctorFilterationPage },
  {
    path: ':id',
    component: CheckoutSuccessPage,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule { }
