import { Action, createAction, props } from "@ngrx/store";

export const booking = createAction(
    '[booking] booking',
    props<{payload: any }>()
);

export const bookingSuccess = createAction(
    '[booking] booking Success',
    props<{payload: any }>()
);

export const bookingFailure = createAction(
    '[booking] booking Failure',
    props<{payload: any }>()
);
  
export const cancelBooking = createAction(
    '[cancel booking] cancel booking',
    props<{payload: any }>()
);

export const cancelBookingSuccess = createAction(
    '[cancel booking] cancel booking Success',
    props<{payload: any }>()
);

export const cancelBookingFailure = createAction(
    '[cancel booking] cancel booking Failure',
    props<{payload: any }>()
);

export const getBookingById = createAction(
    '[getBookingById] getBookingById',
    props<{payload: string }>()
);

export const getBookingByIdSuccess = createAction(
    '[getBookingById] getBookingById Success',
    props<{payload: any }>()
);

export const getBookingByIdFailure = createAction(
    '[getBookingById] getBookingById Failure',
    props<{payload: any }>()
);
  


export interface ActionWithPayload<T> extends Action {
    payload: T;
}