import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  LayoutUtilsService,
  MessageType
} from '../../core/_base/crud';
import { CRUDService } from '../_services/crud.service';

@Component({
  selector: 'kt-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit {
  @Input() info;
  @Input() title: string;
  @Input() modelData: any;
  @Input() instanceData: any;
  @Input() gate: string;
  @Input() query: any;
  @Input() cancelBtnText: string;
  @Input() submitBtnText: string;
  @Input() modalFlag = false;

  @Output()
  notifyParentCancel: EventEmitter<any> = new EventEmitter();
  @Output()
  notifyParentSuccess: EventEmitter<any> = new EventEmitter();
  @Output()
  notifyParentFailure: EventEmitter<any> = new EventEmitter();
  @Output()
  emitFormValue: EventEmitter<any> = new EventEmitter();
  public formData;
  public formGroups: any[];
  public formGroup: FormGroup;
  public wizardFlag: boolean;
  public activeStep: number;
  public totalSteps: number;
  public loading = 0;
  public editorConfig = {
    height: 300,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    file_picker_callback: this.filePickerCallback.bind(this),
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      image | alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help'
  };
  private filePickerCallback(callback, value, meta) {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', 'image/*');

    fileInput.onchange = () => {
      document.querySelector('input[type="url"]')['value'] = 'Loading...';
      
      const file = fileInput.files[0];
      if (file) {
        this.uploadEditorFile(file)
          .then((fileUrl) => {
            callback(fileUrl)
          })
          .catch(error => {
            callback(error['error']['message'])
          });
      }
    };

    fileInput.click();
  }

  constructor(
    private _crudService: CRUDService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router
  ) {
    this.formGroup = new FormGroup({});
  }

  ngOnInit() {
    this.formData = this.modelData.form;
    this.formGroups =
      this.modelData.form && this.modelData.form['groups'];
    this.wizardFlag = this.formData && this.formData.wizardFlag;

    this.activeStep = 1;
    this.totalSteps = this.formGroups && this.formGroups.length;
    // making sure form data is an array for consistency
    if (!Array.isArray(this.formGroups)) {
      this.formGroups = [this.formGroups];
    }

    let group: any = {};
    const formInputs = this.formGroups.reduce((acc, singleForm) => {
      return acc.concat(singleForm['inputs']);
    }, []);

    formInputs.forEach((element, j) => {
      if (!element.gate) {
        element.gate = element.name;
      }
      let inputValidators = [];
      if (element.email) {
        inputValidators.push(Validators.email);
      }
      if (element.required) {
        inputValidators.push(Validators.required);
      }
      group[element.gate] = new FormControl(
        element.value || element.defaultValue || null,
        inputValidators
      );
      if (element.type === 'select' && element.cities) {
        this._crudService
          .checkMeBackendGetData(element.apiend, element.query)
          .then((res) => {
            element.options = res || [];
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (
        element.type === 'select' &&
        !element.simpleOpts &&
        element.apiend
      ) {
        if (element.checkMeBackend) {
          this._crudService
            .checkMeBackendGetData(element.apiend, element.query)
            .then((res) => {
              element.options = res.data || res[element.apiend] || [];
              if (!element.multiple)
                element.options.unshift({ id: null, name: 'None' });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          this._crudService
            .getData(element.apiend, element.query)
            .then((res) => {
              element.options = (res.data ? res.data : res) || [];
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
      if (element.type === 'phone') {
        group['countryCode'] = new FormControl('+20');
      }
    });

    this.formGroup = new FormGroup(group);

    const editData = { ...this.instanceData };
    for (const key in editData) {
      if (
        editData[key] != null &&
        typeof editData[key] === 'object'
      ) {
        editData[key] = editData[key].id || editData[key];
      }
    }
    this.formGroup.patchValue(editData);
  }

  handleDateChange(formControlName, event) {
    // formatting date
    const d = new Date(event.target.value);
    const ye = new Intl.DateTimeFormat('en', {
      year: 'numeric'
    }).format(d);
    const mo = new Intl.DateTimeFormat('en', {
      month: '2-digit'
    }).format(d);
    const da = new Intl.DateTimeFormat('en', {
      day: '2-digit'
    }).format(d);
    this.formGroup.patchValue({
      [formControlName]: `${ye}-${mo}-${da}`
    });
  }

  submit() {
    let sentData = {};
    const inputsTypes = {};

    for (let i = 0; i < this.formGroups.length; i++) {
      const group = this.formGroups[i];
      for (let j = 0; j < group.inputs.length; j++) {
        const input = group.inputs[j];
        inputsTypes[input.gate] = input.type;
      }
    }

    const formValue = this.formGroup.value;
    for (let index in formValue) {
      if (inputsTypes[index] === 'number') {
        formValue[index] = parseFloat(formValue[index]);
      }

      sentData[index] = formValue[index] || false;
      if (index === 'categories') {
        sentData[index] = formValue[index] || [];
      }
      if (
        index === 'covidCountry' ||
        index === 'parentCategory' ||
        index === 'covidType' ||
        index === 'image' ||
        index === 'unImage' ||
        index === 'test' ||
        index === 'bundle' ||
        index === 'category' ||
        index === 'lab' ||
        index === 'type' ||
        index === 'name_ar' ||
        index === 'cptCode' ||
        index === 'lionicCode' ||
        index === 'overview' ||
        index === 'sampleNote' ||
        index === 'description' ||
        index === 'opens_at' ||
        index === 'closes_at' ||
        index === 'lastName' ||
        index === 'birthDate' ||
        index === 'gender' ||
        index === 'covid_category' ||
        index === 'covid_subcategory' ||
        index === 'lastNameAr' ||
        index === 'profileImg' ||
        index === 'centers' ||
        index === 'parentCategoryId' ||
        index === 'subTitle' ||
        index === 'subTitleAr' ||
        index === 'authorName' ||
        index === 'readingTime'
      ) {
        sentData[index] = formValue[index] || null;
        if (Array.isArray(sentData[index])) {
          sentData[index] = sentData[index].filter(Boolean);
        }
      }
      if (index === 'sampleTypeId') {
        sentData['sampleType'] = sentData[index];
        delete sentData[index];
      }
      if (index === 'img_uri' || index === 'logo') {
        sentData[index] =
          (formValue[index] &&
            formValue[index].replace('-api-v1-1', '')) ||
          '';
      }
    }
    if (this.formData['type'] === 'notifyParent') {
      return this.emitFormValue.emit(formValue);
    }

    let modifyPromise;

    if (this.formData['type'] === 'special') {
      let formBody = { ...sentData };
      if (this.formData['wrapperKey']) {
        formBody = {
          [this.formData['wrapperKey']]: { ...sentData }
        };
      }
      modifyPromise = this._crudService.api(
        this.formData['endPoint'],
        formBody,
        this.formData['httpMethod']
      );
    } else {
      if (this.query.id) {
        if (!sentData['password']) delete sentData['password'];
        if (
          this.modelData.checkMeBackend ||
          this.modelData.checkMeBackendPatch
        ) {
          modifyPromise = this._crudService.checkMeBackendEditData(
            this.modelData['patchGate'] || this.gate,
            sentData,
            this.query.id
          );
        } else {
          modifyPromise = this._crudService.editData(
            this.modelData['patchGate'] || this.gate,
            sentData,
            this.query.id
          );
        }
      } else {
        let location = {};
        if (sentData['location']) {
          location['coordinates'] = sentData['location']
            .split(',')
            .map((coordinate) => parseInt(coordinate));
          sentData['location'] = location;
        }
        if (this.gate === 'auth')
          sentData = { auth: { ...sentData } };

        delete this.query.deletedAt;

        if (
          this.modelData.checkMeBackend ||
          this.modelData.checkMeBackendPost
        ) {
          modifyPromise = this._crudService.checkMeBackendAddData(
            this.modelData['postGate'] || this.gate,
            sentData,
            this.query
          );
        } else {
          modifyPromise = this._crudService.addData(
            this.modelData['postGate'] || this.gate,
            sentData,
            this.query
          );
        }
      }
    }

    delete sentData['id'];
    this.loading++;
    return modifyPromise
      .then((res) => {
        this.loading--;

        this._crudService.forwardForceRefresh(true);
        this.layoutUtilsService.showActionNotification(
          res.message || 'Successfully sent',
          MessageType.Create
        );
        if (this.notifyParentSuccess) {
          this.notifyParentSuccess.emit(res);
        }

        // reset uploaded files
        this.formGroups[0]['inputs'].forEach((x) => {
          if (x.type === 'file') {
            x.selectedFile = { name: '' };
            x.imgURL = null;
          }
        });

        if (this.formGroups[1]) {
          this.formGroups[1]['inputs'].forEach((x) => {
            if (x.type === 'file') {
              x.selectedFile = { name: '' };
              x.imgURL = null;
            }
          });
        }

        if (
          !this.query.id &&
          this.modelData.form['onAddRedirectToDetails']
        ) {
          const keys = Object.keys(res);
          for (const k of keys) {
            if (res[k]['id']) {
              this.router.navigateByUrl(`/${k}s/${res[k]['id']}`);
            }
          }
        }
      })
      .catch((err) => {
        this.loading--;

        this.layoutUtilsService.showActionNotification(
          'Failed: ' + err.error.message ||
            'Sorry, something went worng.',
          MessageType.Delete
        );
        if (this.notifyParentFailure) {
          this.notifyParentFailure.emit(err);
        }
      });
  }

  cancelChanges() {
    // FIXME: edit case
    this.formGroup.reset();
    if (this.notifyParentCancel) {
      this.notifyParentCancel.emit(true);
    }
  }

  backChanges() {
    this.activeStep -= 1;
  }

  next() {
    this.activeStep += 1;
  }

  onCountryChange(event) {
    this.formGroup.patchValue({
      countryCode: '+'.concat(event.dialCode)
    });
  }

  isControlHasError(
    controlName: string,
    validationType: string
  ): boolean {
    const control = this.formGroup.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
    return result;
  }

  onFileChange(event, controlName) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      this.formGroups[0]['inputs'].forEach((x) => {
        if (x.type === 'file' && x.gate === controlName) {
          x.uploading++;
          x.selectedFile = file;
          x.imgURL = null;
        }
      });

      if (this.formGroups[1]) {
        this.formGroups[1]['inputs'].forEach((x) => {
          if (x.type === 'file' && x.gate === controlName) {
            x.uploading++;
            x.selectedFile = file;
            x.imgURL = null;
          }
        });
      }

      event.target.value = '';

      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        this.layoutUtilsService.showActionNotification(
          'Not Allowed\n' + 'Only image files are allowed.',
          MessageType.Delete
        );
        return;
      }

      if (this.instanceData) {
        this.instanceData[controlName] = null;
      }

      this.uploadFile(controlName)
        .then((fileUri) => {
          this.formGroups[0]['inputs'].forEach((x) => {
            if (x.type === 'file' && x.gate === controlName) {
              x.uploading--;
              x.imgURL = fileUri;
            }
          });

          if (this.formGroups[1]) {
            this.formGroups[1]['inputs'].forEach((x) => {
              if (x.type === 'file' && x.gate === controlName) {
                x.uploading--;
                x.imgURL = fileUri;
              }
            });
          }

          this.formGroup.patchValue({
            [controlName]: fileUri
          });
          if (this.instanceData) {
            this.instanceData[controlName] = fileUri;
          }
        })
        .catch((err) => {
          this.formGroups[0]['inputs'].forEach((x) => {
            if (x.type === 'file' && x.gate === controlName) {
              x.uploading--;
            }
          });

          if (this.formGroups[1]) {
            this.formGroups[1]['inputs'].forEach((x) => {
              if (x.type === 'file' && x.gate === controlName) {
                x.uploading--;
              }
            });
          }
          this.layoutUtilsService.showActionNotification(
            'Sorry, failed to upload image',
            MessageType.Delete
          );
        });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.formGroups[0]['inputs'].forEach((x) => {
          if (x.type === 'file' && x.gate === controlName) {
            x.imgURL = reader.result;
          }
        });
        if (this.formGroups[1]) {
          this.formGroups[1]['inputs'].forEach((x) => {
            if (x.type === 'file' && x.gate === controlName) {
              x.imgURL = reader.result;
            }
          });
        }
      };
    }
  }

  uploadFile(controlName) {
    let fileInput;

    this.formGroups[0]['inputs'].forEach((x) => {
      if (x.type === 'file' && x.gate == controlName) {
        fileInput = x;
      }
    });

    if (this.formGroups[1]) {
      this.formGroups[1]['inputs'].forEach((x) => {
        if (x.type === 'file' && x.gate == controlName) {
          fileInput = x;
        }
      });
    }

    return this._crudService
      .uploadAttachment(fileInput.selectedFile)
      .then((response) => {
        return response['uploadedFile'].link;
      });
  }

  uploadEditorFile(value) {
    return this._crudService
      .uploadBlogAttachment(value)
      .then((response) => {
        return response['imageUrl']
      });
  }
}
