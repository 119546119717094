import { HttpErrorResponse } from '@angular/common/http';
import { Action, createSelector } from '@ngrx/store';
import { BookingResponse } from '../../models/booking.interface';
import * as fromCheckoutActions from '../actions/checkout.actions';

export const checkoutFeatureKey = 'checkoutFeature';

export interface FeatureState {
    bookingError?: HttpErrorResponse;
    booking?: BookingResponse;
    bookingById?: BookingResponse;
}
export interface ModuleState {
    checkoutFeature: FeatureState;
}

export const selectState  = (state: ModuleState) => state.checkoutFeature;
export const selectBooking = createSelector(
    selectState,
    (state: FeatureState) => state.booking,
);
export const selectBookingById = createSelector(
    selectState,
    (state: FeatureState) => state.bookingById,
);
export const initialState: FeatureState = {
    bookingError: {} as HttpErrorResponse,
    booking: undefined
}

export function checkOutReducer(state: FeatureState = initialState, action: Action): FeatureState {
    switch (action.type) {
      case fromCheckoutActions.booking.type:
        return { ...state, bookingError: undefined, booking: undefined };
  
      case fromCheckoutActions.bookingSuccess.type:
        return { ...state, booking: (action as any).payload };
  
      case fromCheckoutActions.bookingFailure.type:
        return { ...state, bookingError: (action as any).payload };
  
      case fromCheckoutActions.getBookingById.type:
        return { ...state, bookingError: undefined, bookingById: undefined };
  
      case fromCheckoutActions.getBookingByIdSuccess.type:
        return { ...state, bookingById: (action as any).payload };
  
      case fromCheckoutActions.getBookingByIdFailure.type:
        return { ...state, bookingError: (action as any).payload };
    
      default:
        return state;
    }
  }

// const checkOutReducer = createReducer(
//     initialState,
//     on(fromCheckoutActions.booking, (state, action)=> ({...state, bookingError: undefined, booking: undefined})),
//     on(fromCheckoutActions.bookingSuccess, (state, action)=> {
//         return {...state, booking: action.payload}
//     }),
//     on(fromCheckoutActions.bookingFailure, (state, action)=> ({...state, bookingError: action.payload})),


//     on(fromCheckoutActions.getBookingById, (state, action)=> ({...state, bookingError: undefined, bookingById: undefined})),
//     on(fromCheckoutActions.getBookingByIdSuccess, (state, action)=> ({...state, bookingById: action.payload})),
//     on(fromCheckoutActions.getBookingByIdFailure, (state, action)=> ({...state, bookingError: action.payload})),
// )

// export function reducer(state: FeatureState | undefined, action: Action) {        
//     return checkOutReducer(state, action);
// }
