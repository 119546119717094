import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectInput } from '../models/selectInput.interface';
import { SelectInputResponse } from '../models/selectInput.response';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  localStorageSubject$: Subject<boolean> = new Subject();
  apigatewayUrl: string;
  areas: string;
  cities: string;
  auth: string;
  specialities: string;
  apigatewayUrlV2: string;

  constructor(private httpClient: HttpClient) {
    this.apigatewayUrl = '/api';
    this.apigatewayUrlV2 = '/api-v2/api';
    this.areas = '/areas';
    this.cities = '/cities';
    this.auth = '/auth';
    this.specialities = '/specialities';
  }

  public getAllCities$(): Observable<SelectInput[]> {
    const url = `${this.apigatewayUrlV2}${this.cities}?select=id,name&isNotPopulated=true`;
    const headers = {
      'x-access-token': localStorage.getItem('x-access-token') as string
    };
    return this.httpClient.get<SelectInput[]>(url, { headers });
  }
  public getAllAreas$(): Observable<SelectInputResponse> {
    const url = `${this.apigatewayUrl}${this.areas}?select=id,name&isNotPopulated=true&limit=9999`;
    return this.httpClient.get<SelectInputResponse>(url);
  }
  public getAllDoctorAuths$(): Observable<SelectInputResponse> {
    const url = `${this.apigatewayUrl}${this.auth}?accessLevel=doctor`;
    return this.httpClient.get<SelectInputResponse>(url);
  }
  public getAllSpecialities$(): Observable<SelectInputResponse> {
    const url = `${this.apigatewayUrl}${this.specialities}`;
    return this.httpClient.get<SelectInputResponse>(url);
  }
}
