import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CRUDService } from '../../shared/_services/crud.service';
import {
  LayoutUtilsService,
  MessageType
} from '../../core/_base/crud/utils/layout-utils.service';

@Component({
  selector: 'kt-create-chemist',
  templateUrl: './create-chemist.component.html',
  styleUrls: ['./create-chemist.component.scss']
})
export class CreateChemistComponent implements OnInit {
  public formData = {};
  @Input()
  lab;
  public activeStep = 1;
  public totalSteps = 2;
  public authModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          title: 'Auth Account',
          inputs: [
            {
              name: 'Email',
              type: 'string',
              gate: 'email',
              email: true,
              required: true
            },
            {
              name: 'Password',
              type: 'password',
              gate: 'password',
              required: true,
              minLength: 8
            }
          ]
        }
      ]
    }
  };

  public userProfileModel: any = {
    form: {
      type: 'notifyParent',
      groups: [
        {
          inputs: [
            {
              name: 'First Name',
              gate: 'firstName',
              type: 'string',
              required: true
            },
            {
              name: 'Last Name',
              gate: 'lastName',
              type: 'string'
            },

            {
              name: 'Country Code',
              gate: 'countryCode',
              type: 'string'
            },

            {
              name: 'phone',
              type: 'string'
            },

            {
              name: 'Date of Birth',
              gate: 'birthDate',
              type: 'date'
            },
            {
              name: 'gender',
              type: 'select',
              required: true,
              simpleOpts: true,
              options: ['male', 'female']
            }
          ]
        }
      ]
    }
  };

  @Output()
  notifyParent: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    public crudService: CRUDService,
    private layoutUtilService: LayoutUtilsService
  ) { }

  ngOnInit() { }

  moveToChemistStep(data) {
    this.formData['auth'] = data;
    this.activeStep++;
  }

  submitForm(profileData) {
    this.formData['userProfile'] = profileData;
    this.formData['chemist'] = {
      verified: true,
      lab: this.lab ? this.lab.id : null
    };
    this.crudService
      .api('api/auth/chemist', this.formData, 'POST')
      .then((response) => {
        this.activeModal.close('success');
        this.notifyParent.emit(response);
        this.layoutUtilService.showActionNotification(
          'Created Chemist account',
          MessageType.Read
        );
        // created auth account
      })
      .catch((err) => {
        if (err.error.name === 'E_UNIQUE') {
          this.layoutUtilService.showActionNotification(
            'Failed to create account, Email already registered',
            MessageType.Read
          );
        } else {
          this.layoutUtilService.showActionNotification(
            err.error.message || 'Failed to create account',
            MessageType.Read
          );
        }
      });
  }

  goBackStep(event) {
    this.activeStep--;
  }

  closeModal(event) {
    this.activeModal.close();
  }
}
