import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CRUDService } from '../_services/crud.service';
import { LayoutUtilsService } from '../../core/_base/crud';

@Component({
  selector: 'kt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public statsMap;
  public profitsData;
  constructor(
    private _crudService: CRUDService,
    private layoutService: LayoutUtilsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.fetchStats();
  }

  fetchStats() {
    this._crudService
      .api('/api/app/statistics', {}, 'GET')
      .then((res) => {
        this.statsMap = res;
        const checkMeLabCut = this.statsMap.checkme_cut_labs.reduce((acc, curr) => {
          return acc + (curr.lab_orders_price * (curr.cash_percentage / 100));
        }, 0)
        console.log(
          'DashboardComponen -> fetchStats -> this.statsMap',
          this.statsMap
        );
        this.cd.detectChanges();
        console.log(this.profitsData)
        this.profitsData = [
          {
            title: 'Total Orders',
            desc: 'Total Order requested from Patient APP',
            value: this.statsMap.total_orders + ' Order',
            valueClass: 'kt-font-brand'
          },
          {
            title: 'Total Orders Cost',
            desc: 'Total Order prices (including chemist visit fees)',
            value: this.statsMap.total_price + ' EGP',
            valueClass: 'kt-font-success'
          },
          {
            title: 'Total Visit Fees',
            desc: 'Total visit fees for orders',
            value: this.statsMap.total_visit_price + ' EGP',
            valueClass: 'kt-font-brand'
          },
          {
            title: 'CheckMe Cut from visit fees',
            desc: 'Total CheckMe income from visit fees for orders',
            value: this.statsMap.total_visit_price * 0.3 + ' EGP',
            valueClass: 'kt-font-success'
          },
          {
            title: 'Total Lab Fees',
            desc: 'Total Lab fees for orders',
            value: this.statsMap.total_price - this.statsMap.total_visit_price + ' EGP',
            valueClass: 'kt-font-brand'
          },
          {
            title: 'CheckMe Cut from Lab fees',
            desc: 'Total CheckMe income from Labs for orders (not including vat)',
            value: checkMeLabCut + ' EGP',
            valueClass: 'kt-font-success'
          },
          {
            title: 'VAT',
            desc: 'Total VAT on checkMe Income',
            value: (checkMeLabCut * 0.14).toFixed(2) + ' EGP',
            valueClass: 'kt-font-danger'
          },
          {
            title: 'Total CheckMe Income',
            desc: 'Total CheckMe income for orders (including vat)',
            value: ((checkMeLabCut * (1 - 0.14)) + (this.statsMap.total_visit_price * 0.3)).toFixed(2) + ' EGP',
            valueClass: 'kt-font-success'
          },
          {
            title: 'Complaint Reports',
            desc: 'Reports issued by customers',
            value: this.statsMap.total_reports + ' Report',
            valueClass: 'kt-font-danger'
          }
        ];
      })
      .catch((err) => {
        this.layoutService.showActionNotification(
          'Failed to fetch statistics'
        );
      });
  }
}
