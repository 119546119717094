import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'kt-form-modal',
  templateUrl: './generic-form-modal.component.html',
  styleUrls: ['./generic-form-modal.component.scss']
})
export class GenericFormModalComponent {
  @Input() info;
  @Input() title: string;
  @Input() modelData: any;
  @Input() instanceData: any;
  @Input() gate: string;
  @Input() query: any;
  @Input() wizard = false;

  @Output() notifyParentSuccess = new EventEmitter<any>();

  public formData: any[];
  public formGroup: FormGroup;

  constructor(public activeModal: NgbActiveModal) {}

  _onSubmitSuccess() {
    this.activeModal.close('success');
    this.notifyParentSuccess.emit('success');
  }

  _onSubmitFail() {}

  _onCancel(emittedCancel) {
    this.activeModal.close('cancel');
  }
}
