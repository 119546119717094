import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClinicViewModel } from '../../models/clinicViewModel.interface';
import { ClinicService } from '../../services/clinics.service';

@Component({
  selector: 'clinic-list-item',
  templateUrl: './clinic-list-item.component.html',
  styleUrls: ['./clinic-list-item.component.scss']
})
export class ClinicListItemComponent implements OnInit, OnDestroy {
  @Input() clinic!: ClinicViewModel;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isSelected!: boolean;

  constructor(private clinicService: ClinicService) {}

  ngOnInit(): void {
    this.clinicService.currentSelectedClinic$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ clinic, mode }) => {
        if (!clinic) this.isSelected = false;
        else this.isSelected = clinic.id === this.clinic.id;
      });
  }

  emitCurrentSelectedClinic() {
    this.clinicService.currentSelectedClinic$.next({
      clinic: this.clinic,
      mode: 'readonly'
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
