import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Clinic } from '../models/clinic.interface';
import { GetClinicById } from '../models/clinicById.interface';
import { ClinicViewModel } from '../models/clinicViewModel.interface';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  apigatewayUrl: string;
  serviceUrl: string;
  featureUrl: string;
  clinics$: BehaviorSubject<ClinicViewModel[]> = new BehaviorSubject(
    [] as ClinicViewModel[]
  );
  currentSelectedClinic$: BehaviorSubject<{
    clinic: ClinicViewModel | null;
    mode: string;
  }> = new BehaviorSubject({} as any);
  constructor(private httpClient: HttpClient) {
    this.apigatewayUrl = '/api-v2/api';
    this.serviceUrl = '/doctors';
    this.featureUrl = '/clinics';
  }

  getDoctorsClinics$(query): void {
    const url = `${this.apigatewayUrl}${this.serviceUrl}${this.featureUrl}`;
    let params = new HttpParams();
    for (let index in query) {
      params = params.set(index, query[index]);
    }
    const headers = {
      'x-access-token': localStorage.getItem(
        'x-access-token'
      ) as string
    };
    this.httpClient
      .get<ClinicViewModel[]>(url, { params, headers })
      .pipe(take(1))
      .subscribe((data) => this.clinics$.next(data));
  }
  getClinicById$(clinicId: number, doctorAuthId: number) {
    const url = `${this.apigatewayUrl}${this.serviceUrl}${this.featureUrl}/${clinicId}?doctorAuthId=${doctorAuthId}`;
    const headers = {
      'x-access-token': localStorage.getItem(
        'x-access-token'
      ) as string
    };
    return this.httpClient.get<GetClinicById>(url, { headers });
  }

  addClinic(clinic: Clinic, doctorAuthId: number) {
    const url = `${this.apigatewayUrl}${this.serviceUrl}${this.featureUrl}`;
    const headers = {
      'x-access-token': localStorage.getItem(
        'x-access-token'
      ) as string
    };
    return this.httpClient.post<GetClinicById>(
      url,
      { ...clinic, doctorAuthId },
      {
        headers
      }
    );
  }

  updateClinic(clinic: Clinic, doctorAuthId: number) {
    const url = `${this.apigatewayUrl}${this.serviceUrl}${this.featureUrl}/${clinic.id}`;
    const headers = {
      'x-access-token': localStorage.getItem(
        'x-access-token'
      ) as string
    };
    return this.httpClient.patch<GetClinicById>(
      url,
      { ...clinic, doctorAuthId },
      {
        headers
      }
    );
  }
}
