import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
  concatMap,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { DAY } from '../../../app/shared/models/day.type';
import { SnackBarService } from '../../core/_base/layout/pipes/snackbar.service';
import {
  AvailableDays,
  Clinic,
  Schedule
} from '../../models/clinic.interface';
import { GetClinicById } from '../../models/clinicById.interface';
import { ClinicViewModel } from '../../models/clinicViewModel.interface';
import { ClinicService } from '../../services/clinics.service';
import { SelectInput } from '../../shared/models/selectInput.interface';
import { SharedService } from '../../shared/_services/shared.service';

@Component({
  selector: 'clinic-form',
  templateUrl: './clinic-form.component.html',
  styleUrls: ['./clinic-form.component.scss']
})
export class ClinicFormComponent implements OnInit, OnDestroy {
  @Input() centerId;
  @Input() selectedDoctors;
  @Output() notifyParent = new EventEmitter<any>();

  private doctorAuthId;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  cities$!: Observable<SelectInput[]>;
  areas$!: Observable<SelectInput[]>;
  doctorAuths$!: Observable<SelectInput[]>;
  clinicForm!: FormGroup;
  availableDays: AvailableDays[] = [];
  schedules: Schedule[] = [];
  currentActiveDay: string;
  duration: number = 30;
  price: number = 200;
  id?: number;
  someIsInvalid: any;
  readonly!: boolean;
  clinic!: ClinicViewModel | null;
  mode!: string;
  cities!: SelectInput[];
  areas!: SelectInput[];
  doctorAuths!: SelectInput[];
  errorMessage!: string;
  duplicated!: boolean;
  isFirstServeBased = false;
  constructor(
    private sharedService: SharedService,
    private clinicService: ClinicService,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private changeRef: ChangeDetectorRef
  ) {
    moment.locale('en');
    this.currentActiveDay = moment(new Date())
      .format('dddd')
      .toLowerCase();
  }

  ngOnInit(): void {
    if (!this.centerId) {
      this.clinicService.currentSelectedClinic$
        .pipe(
          concatMap(({ clinic, mode }) => {
            this.readonly = mode === 'readonly';
            this.mode = mode;
            this.clinic = clinic;
            if (!this.doctorAuthId) {
              this.doctorAuthId =
                this.route.snapshot.paramMap['params']['id'];
            }
            if (!clinic) return of({} as GetClinicById);

            this.doctorAuthId = this.doctorAuthId || clinic.doctorAuthId;

            return this.clinicService.getClinicById$(
              clinic!.id,
              this.doctorAuthId
            );
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe((clinic) => {
          if (clinic && clinic.id) {
            this.id = clinic.id;
            this.updateMode(clinic);
          } else {
            this.initForm();
            this.schedules = [
              {
                day: this.currentActiveDay as DAY,
                startsAt: '',
                endsAt: ''
              }
            ];
            this.price = 200;
            this.duration = 30;
            this.availableDays = [];
            this.id = 0;
          }
          this.changeRef.detectChanges();
        });
    } else {
      this.initForm();
      this.doctorAuthIdFormControlName.setValidators([
        Validators.required
      ]);
      this.doctorAuths$ = this.sharedService
      .getAllDoctorAuths$()
      .pipe(
        map(
          (response) =>
            (this.doctorAuths = response.data.filter(
              (d) => !this.selectedDoctors.includes(d.id)
            ))
        )
      );
    }

    this.cities$ = this.sharedService
      .getAllCities$()
      .pipe(tap((data) => (this.cities = data)));
    this.areas$ = this.sharedService.getAllAreas$().pipe(
      map((response) => response.data),
      tap((data) => (this.areas = data))
    );
  }

  get name() {
    return this.clinicForm.get('name');
  }
  get doctorAuthIdFormControlName() {
    return this.clinicForm.get('doctorAuthId');
  }
  get areaId() {
    return this.clinicForm.get('areaId');
  }
  get cityId() {
    return this.clinicForm.get('cityId');
  }
  get streetName() {
    return this.clinicForm.get('streetName');
  }
  get buildingNumber() {
    return this.clinicForm.get('buildingNumber');
  }
  get description() {
    return this.clinicForm.get('description');
  }

  private updateMode(clinic: GetClinicById) {
    this.initForm();
    this.clinicForm.patchValue(clinic);
    this.price = clinic.visitCost;
    this.duration = clinic.visitDuration;
    this.availableDays = clinic.clinicDaysAvailability;
    this.schedules = clinic.clinicSchedule;
    this.isFirstServeBased = clinic.isFirstServeBased;
    this.checkOnCurrentPeriodsOfActiveDay();
  }
  private initForm() {
    this.clinicForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      areaId: ['', [Validators.required]],
      doctorAuthId: [null],
      cityId: ['', [Validators.required]],
      streetName: ['', [Validators.required]],
      buildingNumber: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
    if (this.readonly) {
      this.clinicForm.disable();
    }
  }
  private checkOnCurrentPeriodsOfActiveDay() {
    const periods = this.schedules.filter(
      (day) => day.day === this.currentActiveDay
    );
    if (!periods.length) {
      this.schedules.push({
        day: this.currentActiveDay as DAY,
        startsAt: '',
        endsAt: ''
      });
    }
  }
  private handleAfterOnSuccess(
    updatedClinicsArray: ClinicViewModel[],
    selectedClinic: ClinicViewModel
  ) {
    this.clinicService.clinics$.next(updatedClinicsArray);
    this.clinicService.currentSelectedClinic$.next({
      clinic: selectedClinic,
      mode: 'readonly'
    });
  }
  private setAvailableDays(availableDays: AvailableDays[]): string {
    const result: string[] = [];
    availableDays.forEach((element: AvailableDays) => {
      if (element.isAvailable) {
        result.push(element.day);
      }
    });
    return result.join(',');
  }
  private onUpdateSuccess(
    updatedClinic: any,
    clincs: ClinicViewModel[]
  ) {
    const availableDays: string = this.setAvailableDays(
      updatedClinic.clinicDaysAvailability
    );
    const index = clincs.findIndex(
      (clinic: ClinicViewModel) => updatedClinic.id === clinic.id
    );
    clincs[index] = {
      ...updatedClinic,
      area: updatedClinic.area.name,
      city: updatedClinic.city.name,
      availableDays
    };
    this.handleAfterOnSuccess(clincs, clincs[index]);
  }

  setCurrentActiveDay(day: string) {
    this.currentActiveDay = day.toLocaleLowerCase();
    this.checkOnCurrentPeriodsOfActiveDay();
  }
  isAvailableDay() {
    return (
      this.availableDays.find(
        (day) => day.day.toLowerCase() === this.currentActiveDay
      ) &&
      this.availableDays.find(
        (day) => day.day.toLowerCase() === this.currentActiveDay
      ).isAvailable
    );
  }
  toggleChange($event: MatSlideToggleChange) {
    const dayIndex = this.availableDays.findIndex(
      (day) => day.day.toLowerCase() === this.currentActiveDay
    );
    if (dayIndex > -1)
      this.availableDays[dayIndex].isAvailable = $event.checked;
    else
      this.availableDays.push({
        day: this.currentActiveDay as DAY,
        isAvailable: $event.checked,
        id: this.availableDays[this.availableDays.length - 1]
          ? this.availableDays[this.availableDays.length - 1].id++
          : 1
      });
  }
  addNewPeriod() {
    this.schedules.push({
      day: this.currentActiveDay as DAY,
      startsAt: '',
      endsAt: ''
    });
  }
  removePeriod(index: number) {
    this.schedules.splice(index, 1);
  }
  startTimeChanged(startTime: string, index: number) {
    this.schedules[index] = {
      ...this.schedules[index],
      startsAt: startTime
    };
    this.schedules[index].isInValid = !moment(
      this.schedules[index].startsAt,
      'HH:mm A'
    ).isBefore(
      moment(this.schedules[index].endsAt || '12:00 AM', 'HH:mm A')
    );
    this.someIsInvalid = this.schedules.every((x) => x.isInValid);
  }
  endTimeChanged(endTime: string, index: number) {
    this.schedules[index] = {
      ...this.schedules[index],
      endsAt: endTime
    };
    this.schedules[index].isInValid = !moment(
      this.schedules[index].startsAt || '12:00 AM',
      'HH:mm A'
    ).isBefore(moment(this.schedules[index].endsAt, 'HH:mm A'));
    this.someIsInvalid = this.schedules.some((x) => x.isInValid);
  }
  submit() {
    const scheduleWithoutDummies = this.schedules.filter(
      (data) => data.startsAt && data.endsAt
    );
    let clinic: Clinic = {
      ...this.clinicForm.value,
      daysAvailability: this.availableDays,
      schedule: scheduleWithoutDummies,
      visitCost: this.price,
      visitDuration: this.duration,
      isFirstServeBased: this.isFirstServeBased
    };

    if (this.centerId) {
      this.doctorAuthId = clinic.doctorAuthId;
      clinic.centerId = this.centerId;
    } else {
      delete clinic.doctorAuthId;
    }

    if (this.id) {
      clinic = {
        ...clinic,
        id: this.id
      };
      this.clinicService
        .updateClinic(clinic, +this.doctorAuthId)
        .pipe(
          take(1),
          withLatestFrom(this.clinicService.clinics$.pipe(take(1)))
        )
        .subscribe(
          (data: any) => {
            const response = data[0].clinic;
            const clincs: ClinicViewModel[] = [...data[1]];
            this.onUpdateSuccess(response, clincs);
            this.snackBarService.openSnackBar(
              'Clinic is Updated Successfully'
            );
          },
          (error) => {
            if (
              error.error.message.includes(
                'Clinic name already exists'
              )
            ) {
              this.duplicated = true;
            } else {
              this.errorMessage = error.error.message;
            }
          }
        );
    } else {
      this.clinicService
        .addClinic(clinic, +this.doctorAuthId)
        .pipe(
          take(1),
          withLatestFrom(this.clinicService.clinics$.pipe(take(1)))
        )
        .subscribe(
          (data: any) => {
            const response = data[0].clinic;
            const availableDays: string = this.setAvailableDays(
              this.availableDays || []
            );
            const clinicVm = {
              ...response,
              area: this.areas.find((x) => x.id === response.areaId)
                .name,
              city: this.cities.find((x) => x.id === response.cityId)
                .name,
              availableDays
            };
            const clincs: ClinicViewModel[] = [...data[1], clinicVm];
            this.handleAfterOnSuccess(clincs, clinicVm);
            this.snackBarService.openSnackBar(
              'Clinic is Added Successfully'
            );
            this.notifyParent.emit('success');
          },
          (error) => {
            if (
              error.error.message.includes(
                'Clinic name already exists'
              )
            ) {
              this.duplicated = true;
            } else {
              this.errorMessage = error.error.message;
            }
          }
        );
    }
  }
  change(event: MatCheckboxChange) {
    this.isFirstServeBased = event.checked;
  }
  cancel() {
    this.clinicService.currentSelectedClinic$.next({
      clinic: this.clinic,
      mode: 'readonly'
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
