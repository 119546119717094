import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'clinic-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  public isAm = false;
  public isTwelveHours = false;
  public timeHours;

  @Input() title!: string;
  @Input() time!: string;
  @Input() readonly!: boolean;
  @Output() emitTimeChanged = new EventEmitter<string>();
  selectedTime!: moment.Moment;
  constructor() {}

  ngOnInit(): void {
    this.selectedTime = moment(this.time || '12:00 AM', 'HH:mm A');

    this.isAm = this.selectedTime.format('a') === 'pm';
    this.isTwelveHours = +this.selectedTime.format('HH') !== 12;
    this.timeHours = +this.selectedTime.format('HH') % 12;
  }

  setDate(currentTime: moment.Moment) {
    this.selectedTime = currentTime;
    let hours = this.selectedTime.get('hours');
    if (hours > 12) {
      hours %= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    const time = `${hours}:${this.selectedTime.format(
      'mm'
    )} ${this.selectedTime.format('a')}`;
    this.emitTimeChanged.emit(time);
  }
}
