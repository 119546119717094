import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { PartialsModule } from '../partials/partials.module';
import { SharedModule } from '../shared/shared.module';
import { VisitDetailsComponent } from './visit-details/visit-details.component';
import { VisitsRoutingModule } from './visits-routing.module';
import { VisitsComponent } from './visits/visits.component';
import { ItemDetailsModalComponent } from './item-details-modal/item-details-modal.component';

@NgModule({
  declarations: [VisitsComponent, VisitDetailsComponent, ItemDetailsModalComponent],
  entryComponents: [ItemDetailsModalComponent],
  imports: [
    CommonModule,
    VisitsRoutingModule,
    SharedModule,
    PartialsModule,
    CoreModule,
  ],
  exports: [ItemDetailsModalComponent,VisitsComponent]
})
export class VisitsModule {}
