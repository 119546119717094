import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent {
  @Output() notifyParent = new EventEmitter<any>();
  public title: string = 'Add Test';
  public addNoteForm = new FormGroup({
    note: new FormControl(null, Validators.required)
  });

  constructor(public activeModal: NgbActiveModal) {}

  onSubmit() {
    const note = this.addNoteForm.value;
    this.notifyParent.emit(note);
    this.activeModal.close('success');
  }
}
