import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'update-disclaimer',
  templateUrl: './update-disclaimer.component.html',
  styleUrls: ['./update-disclaimer.component.scss']
})
export class UpdateDisclaimerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
