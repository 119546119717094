import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BookingRequest, BookingResponse } from "../models/booking.interface";
import * as environment from '../../../environments/environment'
@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    apigatewayUrl: string;
    serviceUrl: string;
    constructor(private httpClient: HttpClient) {
        this.apigatewayUrl =  environment.environment.apiGatewayV2;
        this.serviceUrl = "/patients";
    }

    public book(booking: BookingRequest) : Observable<BookingResponse> {
        const url = `${this.apigatewayUrl}${this.serviceUrl}/visits/book`;
        const headers = {
            "x-access-token": localStorage.getItem('x-patient-token') || "",
        }
        return this.httpClient.post<BookingResponse>(url, booking, {headers});
    }

    public getVisitById(id: string) : Observable<BookingResponse> {
        const url = `${this.apigatewayUrl}${this.serviceUrl}/visits/${id}`;
        const headers = {
            "x-access-token": localStorage.getItem('x-patient-token') || "",
        }
        return this.httpClient.get<BookingResponse>(url, {headers});
    }

    public cancelBook(visitId: number) : Observable<void> {
        const url = `${this.apigatewayUrl}${this.serviceUrl}/visits/cancel`;
        const headers = {
            "x-access-token": localStorage.getItem('x-patient-token') || "",
        }
        // keep the cancelation reason hardcoded till we implement a dropdown for the user to choose from
        return this.httpClient.patch<void>(url, {visitId, cancellationReasonId: 5}, {headers});
    }
}