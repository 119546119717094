import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment';
import { AvailableDays } from '../../models/clinic.interface';
@Component({
  selector: 'weekly-clinic-calendar',
  templateUrl: './weekly-clinic-calendar.component.html',
  styleUrls: ['./weekly-clinic-calendar.component.scss']
})
export class WeeklyClinicCalendarComponent implements OnInit {
  weekDays: string[];
  currentActiveDay: string;
  @Input() availableDays!: AvailableDays[];
  @Output() emitCurrentActiveDay = new EventEmitter<string>();
  constructor() {
    this.weekDays = [
      'Fri',
      'Thurs',
      'Wednes',
      'Tues',
      'Mon',
      'Sun',
      'Satur'
    ];
    moment.locale('en');
    this.currentActiveDay = moment(new Date()).format('dddd');
  }

  ngOnInit(): void {}

  isAvailableDay(day: string) {
    const fullDay = `${day}day`;
    return (
      this.availableDays.find(
        (day) => day.day.toLowerCase() === fullDay.toLocaleLowerCase()
      ) &&
      this.availableDays.find(
        (day) => day.day.toLowerCase() === fullDay.toLocaleLowerCase()
      ).isAvailable
    );
  }
  onClick(day: string) {
    this.currentActiveDay = `${day}day`;
    this.emitCurrentActiveDay.emit(this.currentActiveDay);
  }
}
