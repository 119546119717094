import { AddNoteComponent } from './add-note/add-note.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GenericFormComponent } from './generic-form/generic-form.component';
import { GenericFormModalComponent } from './generic-form-modal/generic-form-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatTableModule,
  MatPaginatorModule,
  MatTabsModule,
  MatSortModule,
  MatTooltipModule,
  MatDialogModule,
  MatSelectModule,
  MatDividerModule,
  MatIconModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCardModule,
  MatChipsModule,
  MatProgressBarModule,
  MatSliderModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatStepperModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatButtonToggleModule,
  MatBottomSheetModule,
  MatExpansionModule,
  _MatChipListMixinBase,
  MatMenuModule,
  MatTreeModule,
  MatDatepickerModule,
  MatSlideToggleModule
} from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PartialsModule } from '../partials/partials.module';
import { GenericListComponent } from './generic-list/generic-list.component';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddTestsComponent } from './add-tests/add-tests.component';
import { CreateChemistComponent } from '../users/create-chemist/create-chemist.component';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { CreateProfileComponent } from '../users/create-profile/create-profile.component';
import { ExcelService } from '../shared/_services/excel.service';
import { DomChangeDirective } from './add-tests/dom-change-directive';
import { AddLabsComponent } from './add-labs/add-labs.component';
import { CreateMedicalProfileComponent } from '../customercare/create-medical-profile/create-medical-profile.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AddBundlesComponent } from './add-bundles/add-bundles.component';
import { NgHighlightModule } from 'ngx-text-highlight';
import { TestAndBundleConflictWarningComponent } from './test-and-bundle-conflict-warning/test-and-bundle-conflict-warning.component';
import { AddChemistToLabComponent } from '../users/add-chemist-to-lab/add-chemist-to-lab.component';
import { DoctorDashboardButtonComponent } from '../users/doctor-dashboard-button/doctor-dashboard-button.component';
import { ClinicListComponent } from '../users/clinic-list/clinic-list.component';
import { ClinicListItemComponent } from '../users/clinic-list-item/clinic-list-item.component';
import { ClinicFormComponent } from '../users/clinic-form/clinic-form.component';
import { ClinicHeaderComponent } from '../users/clinic-header/clinic-header.component';
import { TimePickerComponent } from '../users/time-picker/time-picker.component';
import { WeeklyClinicCalendarComponent } from '../users/weekly-clinic-calendar/weekly-clinic-calendar.component';
import { StepperCounterComponent } from '../users/stepper-counter/stepper-counter.component';
import { UpdateDisclaimerComponent } from '../users/update-disclaimer/update-disclaimer.component';
import { DaysTranslatorPipe } from '../core/_base/layout';
import { CoreModule } from '../core/core.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CheckMeButtonComponent } from './check-me-button/check-me-button.component';
import { CheckMeWhiteButtonComponent } from './check-me-white-button/check-me-white-button.component';

@NgModule({
  declarations: [
    GenericListComponent,
    GenericFormComponent,
    GenericFormModalComponent,
    DashboardComponent,
    AddTestsComponent,
    CreateChemistComponent,
    AddChemistToLabComponent,
    AddTransactionComponent,
    CreateProfileComponent,
    CreateMedicalProfileComponent,
    DomChangeDirective,
    AddLabsComponent,
    AddBundlesComponent,
    AddNoteComponent,
    TestAndBundleConflictWarningComponent,
    DoctorDashboardButtonComponent,
    ClinicListComponent,
    ClinicListItemComponent,
    ClinicFormComponent,
    ClinicHeaderComponent,
    TimePickerComponent,
    WeeklyClinicCalendarComponent,
    StepperCounterComponent,
    UpdateDisclaimerComponent,
    CheckMeButtonComponent,
    CheckMeWhiteButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    PartialsModule,
    // Angular Material Module
    MatDatepickerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTreeModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCardModule,
    MatChipsModule,
    MatProgressBarModule,
    MatSliderModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatBottomSheetModule,
    MatExpansionModule,
    Ng2TelInputModule,
    MatSlideToggleModule,
    NgHighlightModule,
    EditorModule,
    CoreModule
  ],
  providers: [ExcelService],
  entryComponents: [
    GenericFormModalComponent,
    AddTestsComponent,
    CreateChemistComponent,
    AddChemistToLabComponent,
    AddTransactionComponent,
    CreateProfileComponent,
    CreateMedicalProfileComponent,
    AddLabsComponent,
    AddBundlesComponent,
    AddNoteComponent,
    TestAndBundleConflictWarningComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTreeModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCardModule,
    MatChipsModule,
    MatProgressBarModule,
    MatSliderModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatCheckboxModule,
    EditorModule,
    // Exported components
    GenericListComponent,
    GenericFormComponent,
    GenericFormModalComponent,
    AddTestsComponent,
    AddTransactionComponent,
    AddLabsComponent,
    AddBundlesComponent,
    AddNoteComponent,
    TestAndBundleConflictWarningComponent,
    DoctorDashboardButtonComponent,
    ClinicListComponent,
    ClinicListItemComponent,
    ClinicFormComponent,
    ClinicHeaderComponent,
    TimePickerComponent,
    WeeklyClinicCalendarComponent,
    StepperCounterComponent,
    UpdateDisclaimerComponent,
    CreateChemistComponent,
    AddChemistToLabComponent,
    CreateProfileComponent,
    CreateMedicalProfileComponent,
    CheckMeButtonComponent,
    CheckMeWhiteButtonComponent,
  ]
})
export class SharedModule { }
