import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClinicViewModel } from '../../models/clinicViewModel.interface';
import { ClinicService } from '../../services/clinics.service';

@Component({
  selector: 'clinic-list',
  templateUrl: './clinic-list.component.html',
  styleUrls: ['./clinic-list.component.scss']
})
export class ClinicListComponent implements OnInit {
  private doctorAuthId;

  clinics$!: Observable<ClinicViewModel[]>;

  constructor(
    private clinicService: ClinicService,
    private route: ActivatedRoute
  ) {
    this.clinics$ = this.clinicService.clinics$.pipe(
      map((data) => data),
      tap((data) => {
        if (data && data.length) {
          this.clinicService.currentSelectedClinic$.next({
            clinic: data[0],
            mode: 'readonly'
          });          
        } else {
          this.clinicService.currentSelectedClinic$.next({
            clinic: null,
            mode: 'add'
          }); 
        }
      })
    );
  }

  ngOnInit(): void {
    this.doctorAuthId = this.route.snapshot.paramMap['params']['id'];
    this.clinicService.getDoctorsClinics$({
      doctorAuthId: this.doctorAuthId
    });
  }
}
