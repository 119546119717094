import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {
  public visitStatus = ['booked', 'completed', 'cancelled'];
  
  visitsModel: any = {
    list: [
      {
        display: 'ID',
        type: 'number',
        key: 'id',
        search: true
      },
      {
        display: 'Account Name',
        type: 'string',
        key: 'accountName',
        search: true
      },
      {
        display: 'Account Phone',
        type: 'string',
        key: 'accountPhoneNumber',
        search: true
      },
      {
        display: 'Doctor Name',
        type: 'string',
        key: 'doctorName',
        search: true
      },
      {
        display: 'Visit Cost',
        type: 'string',
        key: 'visitCost',
      },
      {
        display: 'CheckMe Percentage',
        type: 'string',
        key: 'CheckMeVisitCostPercentage',
      },
      {
        display: 'CheckMe Profit',
        type: 'string',
        key: 'CheckMeProfit',
      },
      {
        display: 'Status',
        type: 'string',
        key: 'status',
        search: true
      },
      {
        display: 'Visit Date',
        type: 'date',
        key: 'visitDate',
        search: true
      },
      {
        display: 'Created At',
        type: 'date',
        key: 'createdAt',
        search: true
      },
      {
        display: 'Actions',
        type: 'array',
        key: '',
        actions: {
          view: '/visits/details',
          openViewInNewTab: true,
        }
      }
    ],
    filters: [
      {
        label: 'Status',
        options: this.visitStatus.map((x) => ({
          label: x,
          value: x
        })),
        queryParam: 'status'
      },
    ],
    dateFilter: true,
  };
  
  constructor(private router:Router) {
    
  }
  ngOnInit() {}
}
