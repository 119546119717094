import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CRUDService } from '../../shared/_services/crud.service';
import { ItemDetailsModalComponent } from '../item-details-modal/item-details-modal.component';

@Component({
  selector: 'kt-visit-details',
  templateUrl: './visit-details.component.html',
  styleUrls: ['./visit-details.component.scss']
})
export class VisitDetailsComponent implements OnInit {
  public visitId: string;
  public visit;
  displayedColumns: string[] = [
    'accountHolderName',
    'accountHolderPhone',
    'status',
    'visitCost',
    'CheckMeVisitCostPercentage',
    'CheckMeProfit',
    'startTime',
    'createdAt'
  ];

  visitDataSource = [];

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private _crudService: CRUDService
  ) {}

  ngOnInit() {
    this.visitId = this.route.snapshot.paramMap['params']['id'];
    this._crudService
      .checkMeBackendGetOne('admin/visits', this.visitId)
      .subscribe(
        (res) => {
          this.visit = res;
          this.visitDataSource.push(res);
          this.table.renderRows();
        },
        (error) => {
          console.error('Could not fetch TEST');
          console.error(error);
        }
      );
  }

  viewItemDetails(item, isTest = true) {
    const modalRef = this.modalService.open(
      ItemDetailsModalComponent,
      {
        size: 'lg'
      }
    );
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.isTest = isTest;
  }
}
