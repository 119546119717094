import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatPaginator, MatSelect } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs/internal/observable/interval';
import { startWith, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import {
  LayoutUtilsService,
  MessageType
} from '../../core/_base/crud';
import { WebsocketService } from '../../services/websocket.service';
import { ExcelService } from '../../shared/_services/excel.service';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { CRUDService } from '../_services/crud.service';

@Component({
  selector: 'kt-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() modelData: any;
  @Input() alert: any;
  @Input() gate: string;
  @Input() query: any;
  @Input() exportAsExcel = true;
  @Input() wizard = false;
  @Input() search = true;
  @Input() paginate = true;
  @Input() addFlag: boolean = true;
  @Input() addRouter: boolean = true;
  @Input() enabledTestsFlag = false;
  @Input() refreshInterval: number;
  @Input() pageSize: number = 10;
  @Output() addClickEvent = new EventEmitter<any>();
  @Output() listChanged = new EventEmitter<any>();
  @Output() customActionClickEvent = new EventEmitter<any>();
  @Output() customViewEvent = new EventEmitter<any>();

  public displayedColumns: string[];
  public totalLength: number;
  public listData: object[];
  public numberOfTransactions: object[];

  public loader: boolean;
  public Columns: any[] = [];
  public Column: string[] = [];
  public selectedItem: any;
  public newRequest: Object;
  public refreshListner: any;
  public intervalListener: any;
  public startDate: Date;
  public endDate: Date;
  public searchQuery = {};
  public paginationQuery = {
    skip: 0,
    limit: this.pageSize
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatSelect) matSelect: MatSelect;

  constructor(
    private _crudService: CRUDService,
    private layoutUtilsService: LayoutUtilsService,
    private modalService: NgbModal,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private excelService: ExcelService,
    private webSocketService: WebsocketService
  ) {}

  ngOnInit() {
    //update list and notify on new requests
    delete this.query.startDate;
    delete this.query.endDate;
    this.webSocketService.listen('new_order').subscribe((data) => {
      if (
        (data['requestType'] === 'test' &&
          this.gate === 'orderrequests') ||
        (data['requestType'] === 'prescription' &&
          this.gate === 'prescriptions') ||
        this.gate === 'allrequests'
      )
        this.loadList();
      Swal.fire({
        title: `New ${data['requestType']} Order`,
        text: `New ${data['requestType']} Order number: ${data['request']['id']}`,
        icon: 'success'
      });
    });

    //update list and notify on cancel order
    this.webSocketService.listen('cancel_order').subscribe((data) => {
      if (
        data['requestType'] === 'cancel' &&
        (this.gate === 'orderrequests' || this.gate === 'allrequests')
      )
        this.loadList();
      this.layoutUtilsService.showActionNotification(
        `Order number: ${data['request']['id']} has been cancelled`
      );
    });

    this.loader = false;
    this.Columns = this.modelData.list;
    this.Columns.forEach((element) => {
      this.Column.push(element.display);
    });
    // if (this.enabledTestsFlag) {
    //   // this.getLabsFilters();
    // }
    if (this.refreshInterval > 0) {
      // auto refresh mode
      this.intervalListener = interval(this.refreshInterval * 1000)
        .pipe(
          startWith(0),
          switchMap(() => this.loadList())
        )
        .subscribe();
    } else {
      // single fetch mode
      this.loadList();
    }
    // force refresh triggered from other components
    this.refreshListner =
      this._crudService.forceRefreshValue.subscribe((res) => {
        if (res) {
          this.loadList();
        }
      });
  }

  async exportExcel(): Promise<void> {
    let data: object[];

    this.applyDateFilter();
    if (this.title === 'Chemist Accounts')
      this.query.deletedAt = null; // don not fetch soft deleted chemists
    if (this.query.limit) delete this.query.limit;
    if (this.query.skip) delete this.query.skip;

    const response = await this._crudService.getData(this.gate, {
      ...this.query
    });

    switch (this.title) {
      case 'Chemist Accounts':
        data = response.data.map((row) => ({
          ID: row['id'],
          Verified: row['verified'] ? 'True' : 'False',
          Active: row['active'] ? 'True' : 'False',
          Email: row['email'],
          Phone: row['phone'],
          Name: `${row['name']}`,
          CreatedAt: `${row['createdAt']}`
        }));
        break;
      case 'Lab':
        data = response.data.map((row) => ({
          ID: row['id'],
          Name: row['name'],
          Featured: row['special'] ? 'True' : 'False',
          Published: row['published'] ? 'True' : 'False',
          Type: row['type'],
          'Cash Percentage': row['cashPercentage'],
          'Credit Percentage': row['creditPercentage']
        }));
        break;
      default:
        data = response.data;
        break;
    }
    this.excelService.exportAsExcelFile(
      data,
      this.title ? this.title.replace(/ /g, '_') : 'table'
    );
  }

  // async getLabsFilters() {
  //   try {
  //     const labs = await this._crudService.api('/api/labs');
  //     const filters = labs.data.map(lab => {
  //       return { 'label': lab.name, 'value': lab.id }
  //     })
  //     this.modelData.filters = [{ 'label': 'Lab', 'options': filters, queryParam: 'lab' }]
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  sendSearch(value) {
    this.loader = false;
    this.searchQuery['searchFields'] = [];
    this.searchQuery['searchTerm'] = value;
    this.modelData.list.forEach((listItem) => {
      if (listItem.search) {
        this.searchQuery['searchFields'].push(listItem.searchKey);
      }
    });
    this.searchQuery['searchFields'] =
      this.searchQuery['searchFields'].join(',');
    if (+value === 0) {
      return this.loadList();
    }

    // reset pagination
    this.paginator.pageIndex = 0;
    this.paginationQuery['skip'] = 0;

    this.loadList();
  }

  changePage() {
    this.paginationQuery['skip'] =
      this.paginator.pageIndex * this.pageSize;
    this.paginationQuery['limit'] = this.pageSize;

    this.loadList();
  }

  delete(data: any) {
    const _title: string = `${this.title} Delete`;
    const _description: string = `Are you sure to permanently delete this ${this.title}?`;
    const _waitDesciption: string = `${this.title} is  being deleted...`;
    const _deleteMessage = `${this.title} has been deleted`;
    const q = { ...this.query, ...{ id: data.id } };

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) return;
      try {
        let response;
        if (this.gate === 'auth' || this.gate === 'role') {
          await this._crudService.api(
            `/api/${this.gate}/${data.id}`,
            {},
            'DELETE'
          );
        } else {
          if (this.modelData.checkMeBackend || this.modelData.checkMeBackendDelete) {
            response = await this._crudService.checkMeBackendDeleteData(
              this.modelData.deleteGate || this.gate,
              data.id
            );
          } else {
            response = await this._crudService.deleteData(
              this.gate === 'orderrequestvs'
                ? 'orderrequests'
                : this.modelData['deleteGate'] || this.gate,
              q
            );
          }
        }
        this.listChanged.emit(true);
        this.layoutUtilsService.showActionNotification(
          response.message || _deleteMessage
        );
        this.loadList().then(() => {
          this.changeDetectorRefs.detectChanges();
        });
      } catch (error) {
        this.layoutUtilsService.showActionNotification(
          error.error.message || `Failed to delete ${this.title}`,
          MessageType.Delete
        );
        // console.error(error);
      }
    });
  }

  handleFilterChange($event, filterObj) {
    const eventValue = $event.value;
    if (eventValue && eventValue.length > 0) {
      this.query[filterObj.queryParam] = eventValue;
    } else {
      delete this.query[filterObj.queryParam];
    }

    // reset pagination
    this.paginator.pageIndex = 0;
    this.paginationQuery['skip'] = 0;

    this.loadList();
  }

  applyDateFilter() {
    if (this.startDate)
      this.query['startDate'] = `${this.startDate.getFullYear()}-${
        this.startDate.getMonth() + 1
      }-${this.startDate.getDate()}`;
    if (this.endDate)
      this.query['endDate'] = `${this.endDate.getFullYear()}-${
        this.endDate.getMonth() + 1
      }-${this.endDate.getDate()} 23:59:59`;
  }

  resetFilters() {
    this.startDate = null;
    this.endDate = null;
    this.query = {};
    this.searchQuery = {};
    this.pageSize = 10;
    this.paginationQuery = {
      skip: 0,
      limit: this.pageSize
    };

    // Unselect the selected values in all the select lists
    this.matSelect['_results'].forEach((element) => {
      element.writeValue(null);
    });

    this.loadList();
  }

  async loadList() {
    this.applyDateFilter();

    let query = {
      ...this.query,
      ...this.searchQuery,
      ...this.paginationQuery
    };

    try {
      let res;

      if (!query.searchTerm) {
        delete query.searchFields;
        delete query.searchTerm;
      }
      if (
        this.gate === 'cities' ||
        this.gate === 'cancellationreasons' ||
        this.gate === 'visits' ||
        this.gate === 'centers' ||
        this.modelData.checkMeBackend
      ) {
        if (this.gate === 'visits' && query.searchTerm) {
          query.searchKey = query.searchTerm;
          delete query.searchFields;
          delete query.searchTerm;
        }

        if (query.startDate) {
          query.createdAtStartRange = query.startDate;
          delete query.startDate;
        }
        
        if (query.endDate) {
          query.createdAtEndRange = query.endDate.split(' ')[0];
          delete query.endDate;
        }

        res = await this._crudService.checkMeBackendGetData(
          this.gate,
          query
        );
      } else {
        res = await this._crudService.getData(this.gate, query);
      }

      this.totalLength = res.count;
      this.listData = res.data || res.results || res[this.gate] || res;

      if (this.gate === 'ordersStats') {
        const res2 = await this._crudService.getData(
          '/number-of-transactions',
          query
        );

        this.numberOfTransactions = res2.numberOfTransactions;
      }

      if (
        this.modelData.form &&
        !this.modelData.form['onAddRedirectToDetails']
      ) {
        this.changeDetectorRefs.detectChanges();
      }
      this.loader = true;
    } catch (err) {
      this.layoutUtilsService.showActionNotification(
        'Could not fetch list'
      );
      console.error('Failed to load list', err);
    }
  }

  edit(item) {
    let q = {
      id: item.id
    };
    this.selectedItem = item;
    this.open(q, 'edit');
  }
  // opens edit/add modal
  open(query = {}, mode = 'add') {
    let addModeFlag = mode === 'add';
    let info = {
      title: addModeFlag ? 'Add new' : 'Edit',
      gate: this.gate,
      mode: addModeFlag ? 'add' : 'edit',
      instance: this.selectedItem
    };

    this.addClickEvent.emit(info);
    if (
      !this.modelData.externalForm ||
      (this.modelData.inlineEdit && mode === 'edit')
    ) {
      let q = { ...this.query, ...query };
      const self = this;
      const modalRef = this.modalService.open(
        this.modelData.customEditModal
          ? this.modelData.customEditModal
          : GenericFormModalComponent,
        {
          size: 'lg'
        }
      );

      if (mode === 'edit') {
        modalRef.componentInstance.info = info;
      }

      modalRef.componentInstance.title = info.title;
      modalRef.componentInstance.modelData = this.modelData;
      modalRef.componentInstance.gate = this.gate;
      modalRef.componentInstance.query = q;
      modalRef.componentInstance.instanceData = addModeFlag
        ? {}
        : this.selectedItem;
      modalRef.componentInstance.wizard = this.wizard;

      if (!this.modelData.customEditModal)
        modalRef.componentInstance.notifyParentSuccess.subscribe(
          (res) => {
            this.loadList();
          }
        );
      modalRef.result
        .then((result) => {
          if (result === 'success') {
            self.loadList(); // Refresh Data in table grid
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  handleViewClick(mode, element, openViewInNewTab = false) {
    if (mode === 'custom') {
      this.customViewEvent.emit(element);
    } else if (element.branches) {
      this.router.navigate([mode, element.id]);
    } else if (element.type === 'test' || element.scan === false) {
      window.open(`/requests/orders/details/${element.id}`, '_blank');
    } else if (element.type === 'prescription') {
      window.open(
        `/requests/prescription/details/${element.id}`,
        '_blank'
      );
    } else if (element.type === 'scan' || element.scan) {
      window.open(`/requests/scan/details/${element.id}`, '_blank');
    } else if (openViewInNewTab) {
      window.open(`${mode}/${element.id}`, '_blank');
    } else {      
      this.router.navigate([mode, element.id]);
    }
  }

  async onChangeActiveStatus(event, element) {
    try {
      await this._crudService.editData(
        this.gate,
        { isActive: event.checked },
        element.id
      );

      this.loadList();
    } catch (err) {
      this.layoutUtilsService.showActionNotification(
        'Could not update value'
      );
    }
  }

  onChangePage(event) {
    this.pageSize = event.pageSize;
  }

  ngOnDestroy() {
    this.startDate = null;
    this.endDate = null;
    this.webSocketService.removeListener('new_order');
    this.webSocketService.removeListener('cancel_order');
    if (this.refreshListner) {
      this.refreshListner.unsubscribe();
    }
    if (this.intervalListener) {
      this.intervalListener.unsubscribe();
    }
  }
}
